<template>
  <div class="">
    <img src="../../assets/img/digitalTransformation/szhgztop_img2.png" alt="" class="banner" />
    <div class="wrapper clearfix">
      <div class="search fl-c-b">
        <div class="fl-c">
          <label>所属行业</label>
          <el-select v-model="type">
            <el-option label="" value=""></el-option>
          </el-select>
        </div>
        <div class="fl-c">
          <label>数字化模块</label>
          <el-select v-model="digitalModule">
            <el-option label="" value=""></el-option>
          </el-select>
        </div>
        <div class="fl-c">
          <label>参考报价</label>
          <el-input v-model="offer" placeholder="请输入参考报价" :maxlength="20" clearable></el-input>
        </div>
        <div class="fl-c">
          <label>工期需求</label>
          <el-select v-model="demand">
            <el-option label="" value=""></el-option>
          </el-select>
        </div>
      </div>
      <div class="session" style="position: relative">
        <div class="session-title">服务商推荐</div>
        <swiper class="recommend" navigation :options="options">
          <swiper-slide>
            <div class="recommend-item" @click="go('DTDetails')">
              <img src="../../assets/img/digitalTransformation/szhgzfws_img1.png" alt="" class="recommend-img" />
              <div class="recommend-name">CI&T数字化服务商</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="recommend-item" @click="go('DTDetails')">
              <img src="../../assets/img/digitalTransformation/szhgzfws_img2.png" alt="" class="recommend-img" />
              <div class="recommend-name">IBM数字化服务提供商</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="recommend-item" @click="go('DTDetails')">
              <img src="../../assets/img/digitalTransformation/szhgzfws_img3.png" alt="" class="recommend-img" />
              <div class="recommend-name">微盟数字化</div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev"></div>
        <!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
        <div class="swiper-button-next"></div>
        <!--右箭头。如果放置在swiper-container外面，需要自定义样式。-->
      </div>
      <div class="session">
        <div class="header" style="margin-bottom: 0">
          <div class="title">服务商信息</div>
          <img src="../../assets/img/businessintelligentpush/box_icon_more@3x.png" alt="" @click="go('DTList')" class="more-list" />
        </div>
        <div class="service-list clearfix">
          <div class="service-item fl" v-for="(item, index) in 6" :key="index" @click="go('DTDetails')">
            <div class="logo">暂无LOGO</div>
            <div class="item-info">
              <div class="name">CI&T</div>
              <div class="tags clearfix">
                <div class="tag fl">财务数字化</div>
                <div class="tag fl">电子行业</div>
                <div class="tag fl">参考价10W</div>
                <div class="tag fl">工期短</div>
              </div>
            </div>
            <div class="arrow">
              <img src="../../assets/img/digitalTransformation/box_icon_more.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix" style="margin-bottom: 30px">
        <div class="session fl" style="margin: 0 16px 0 0; width: calc((100% - 16px) / 2)">
          <div class="session-title">行业资讯</div>
          <div class="list-item">发电公司3号机组机炉外管更换询价采购结果公告</div>
          <div class="list-item">数字化转型第一弹：智能化技术改造，助企业提升！</div>
          <div class="list-item">加快推进产业数字化改造 我市五家企业列入省级计划_服务</div>
          <div class="list-item">一家传统企业数字化改造背后的营商环境之变 - 浙报融媒体</div>
          <div class="list-item">大观区经信局主动服务,助力企业数字化转型</div>
        </div>
        <div class="session fl" style="margin: 0; width: calc((100% - 16px) / 2)">
          <div class="session-title">园内动态</div>
          <div class="list-item">温州市博弘电力有限公司<span class="blue">6分钟前浏览了</span>西门子</div>
          <div class="list-item">浙江怀远电器有限公司<span class="blue">1小时前浏览了</span>CI&T</div>
          <div class="list-item">浙江怀远电器有限公司<span class="blue">1小时前浏览了</span>CI&T</div>
          <div class="list-item">浙江怀远电器有限公司<span class="blue">1小时前浏览了</span>CI&T</div>
          <div class="list-item">浙江怀远电器有限公司<span class="blue">1小时前浏览了</span>CI&T</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper.scss';
// import Swiper core and required components
// import SwiperCore, { Navigation } from 'swiper';
// install Swiper components
// SwiperCore.use([Navigation]);

export default {
  name: 'demandDetails',
  data() {
    return {
      options: {
        slidesPerView: 3,
        spaceBetween: 21,
        observer: true,
        observeParents: true,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      offer: '',
      type: '',
      digitalModule: '',
      demand: '',
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    go(name) {
      this.$router.push({ name });
    },
  },

  mounted() {},
  created() {},
};
</script>


<style lang='scss' scoped>
.banner {
  width: 100%;
}
.session {
  margin-bottom: 16px;
  padding: 0 20px 30px;
  background-color: #fff;
}
.search {
  padding: 16px;
  background: #ffffff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: -19px;
  margin-bottom: 16px;
  position: relative;
  label {
    font-size: 14px;
    color: #666666;
    text-align: right;
    line-height: 20px;
    margin-right: 12px;
    min-width: 70px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dddddd;
  height: 51px;
  width: 100%;
  margin-bottom: 15px;
}

.header .title {
  font-size: 16px;
  color: #000000;
  line-height: 20px;
}
.session-title {
  font-size: 26px;
  color: #333333;
  line-height: 74px;
  position: relative;
  border-bottom: 1px solid #ddd;
  padding-left: 14px;
  position: relative;
  font-weight: 600;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 28px;
    width: 4px;
    height: 20px;
    background: #4393f8;
    border-radius: 3px;
  }
}
.recommend {
  height: 210px;
  margin-top: 16px;
}
.recommend-item {
  position: relative;
  cursor: pointer;
}
.recommend-img {
  width: 100%;
  height: 100%;
  display: block;
}
.recommend-name {
  position: absolute;
  top: 95px;
  left: 0;
  font-size: 16px;
  color: #ffffff;
  line-height: 21px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 175px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  z-index: 9;
  cursor: pointer;
  &:focus{
    outline: none;
  }
}
.swiper-button-next {
  right: 32px;
  background: rgba(0, 0, 0, 0.6) url(../../assets/img/digitalTransformation/icon_more_right.png) center no-repeat;
}
.swiper-button-prev {
  left: 32px;
  background: rgba(0, 0, 0, 0.6) url(../../assets/img/digitalTransformation/icon_more_left.png) center no-repeat;
}

.more-list {
  width: 16px;
  height: 16px;
  font-size: 0;
  cursor: pointer;
}

.header .btn-primary {
  background: #0e459c;
}
.service-item {
  /* height: 136px; */
  margin-right: 17px;
  margin-top: 16px;
  background-image: linear-gradient(269deg, #f7fbff 0%, #eff7ff 100%);
  border-radius: 4px;
  width: calc((100% - 34px) / 3);
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
}

.service-item:nth-of-type(3n) {
  margin-right: 0;
}

.service-item .logo {
  width: 104px;
  height: 104px;
  background-color: #fff;
  font-size: 16px;
  color: #6d8ecd;
  font-weight: 600;
  text-align: center;
  line-height: 104px;
  margin-right: 24px;
}

.arrow {
  width: 24px;
  height: 24px;
  background-image: linear-gradient(90deg, #82a6ff 0%, #255ada 100%);
  border-radius: 12px 0 0 12px;
  position: absolute;
  right: 0;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.arrow img {
  margin-left: 6px;
}
.item-info {
  width: calc(100% - 124px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 104px;
}

.item-info .name {
  font-size: 16px;
  color: #333333;
  line-height: 16px;
  margin-bottom: 16px;
  font-weight: 600;
}

.item-info .tag {
  background: rgba(37, 90, 218, 0.2);
  border-radius: 4px;
  margin-right: 8px;
  margin-top: 8px;
  height: 24px;
  width: 96px;
  font-size: 12px;
  color: #255ada;
  text-align: center;
  line-height: 24px;
}
.list-item {
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  margin-top: 16px;
}

.blue {
  color: #6a96dc;
  margin: 0 8px;
}
</style>